import { BackOfficePerson, BackOfficePersonProductRoles, BackOfficeProduct } from '@models/backOffice';
import Pagination from '@models/pagination';
import api from '@services/api';

interface PersonRequest {
  searchTerm?: string;
  page: number;
  size: number;
  timestamp: number;
}

interface PersonByEmailAndProductRequest {
  email?: string;
  productCode?: string;
}

interface PersonProductRole {
  email?: string;
  code?: string;
  roles: Array<string>;
}

export const backOfficeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    person: builder.query<Pagination<BackOfficePerson>, PersonRequest>({
      query: (input: PersonRequest) => ({
        url: encodeURI(
          `/backoffice/person/searchByTerm?searchTerm=${input.searchTerm}&size=${input.size}&page=${input.page}`,
        ),
        method: 'GET',
        providesTags: ['BackOffice'],
      }),
    }),
    products: builder.query<Array<BackOfficeProduct>, void>({
      query: () => ({
        url: encodeURI(`/backoffice/products`),
        method: 'GET',
        providesTags: ['BackOffice'],
      }),
    }),
    personProductsRoles: builder.query<Pagination<BackOfficeProduct>, PersonRequest>({
      query: (input: PersonRequest) => ({
        url: encodeURI(
          `/backoffice/person-product-role/searchByTerm?${
            input.searchTerm ? `searchTerm=${input.searchTerm}&` : ''
          }size=${input.size}&page=${input.page}`,
        ),
        method: 'GET',
        providesTags: ['BackOffice'],
      }),
    }),
    personProductsRolesByEmailProduct: builder.query<BackOfficePersonProductRoles, PersonByEmailAndProductRequest>({
      query: (input: PersonByEmailAndProductRequest) => ({
        url: encodeURI(
          `/backoffice/person-product-role/searchByEmailAndProduct?email=${input.email}&productCode=${input.productCode}`,
        ),
        method: 'GET',
      }),
      providesTags: ['BackOffice'],
    }),

    updatePersonProductRole: builder.mutation<PersonProductRole, PersonProductRole>({
      query: (personProductRole) => ({
        url: `/backoffice/person-product-role`,
        method: 'PUT',
        body: personProductRole,
        invalidatesTags: ['BackOffice'],
      }),
    }),
  }),
});

export const {
  usePersonQuery,
  useProductsQuery,
  usePersonProductsRolesQuery,
  usePersonProductsRolesByEmailProductQuery,
  useUpdatePersonProductRoleMutation,
} = backOfficeApi;
