import { PAGE_PATHS } from '@/app/constants/pages';
import isAuthorized from '@helpers/authorization';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface RouterProps {
  roles: string[];
}

/**
 * <p>
 * This component is a wrapp to be used around the routes that
 * you want to secure, and it's also provied a set of roles that
 * the logged user must have to grant access for a specific set
 * of routes.
 * @param props @see RouterProps
 * @returns the component RequireAuth
 */
function RequireAuth(props: RouterProps) {
  const { t } = useTranslation('authorization');
  const { keycloak } = useKeycloak();
  const { roles } = props;

  if (isAuthorized(keycloak, roles)) {
    return <Outlet />;
  }

  toast.error(`${t('Sem permissões para acessar página')}`, {
    position: toast.POSITION.TOP_RIGHT,
    delay: 1,
  });

  return <Navigate to={PAGE_PATHS.ROOT} />;
}

export default RequireAuth;
