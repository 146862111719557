import { useKeycloak } from '@react-keycloak/web';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * <p>
 * This component is a wrapp to be used around the routes that
 * you want to secure. Its goal is to check if the user is
 * authenticated (he has a token), thus the user is directed
 * to the intended page. Otherwise he is redirected to
 * the login page
 * @param
 * @returns the component RequireLogin
 */
function RequireLogin() {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}

export default RequireLogin;
